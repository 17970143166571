import '@angular/common/locales/global/es';

import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export const SUPPORTED_LANGS = {
  en: 'en',
  es: 'es',
  it: 'it'
};

type SupportedLangsStrings = keyof typeof SUPPORTED_LANGS;

export const defaultLang = SUPPORTED_LANGS.en;

// Factory for the TranslateModule
export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/', '/assets/i18n/instant/']);
}

/**
 * Remove the country from the language (en-UK -> en)
 * @param language - The navigator language
 * @returns A translation json valid name
 */
export function formatLanguage(language: string): string {
  const lan = language.split('-')[0] as SupportedLangsStrings;

  return SUPPORTED_LANGS[lan] ? lan : defaultLang;
}
